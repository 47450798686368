import React, { FC, Fragment, useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {
  Button,
  Card,
  Col,
  Pagination,
  ProgressBar,
  Row,
  Table,
} from "react-bootstrap";
import { ResponsiveDataTable } from "./dashboarddata/ResponsiveDataTable";
export default function View_invoice() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [filter, setFilter] = useState({ location: "" });
  const [totalPages, setTotalPages] = useState();
  const [dataRecord, setDataRecord] = useState("");
  const [open, setOpen] = useState(false);
  const [endDate, setEndDate] = useState(new Date());
  const [CountyList, setCountyList] = useState([]);
  const [pagination, setPagination] = useState("");
  const changeHandler = (name) => async (event) => {
    setFilter({ ...filter, [name]: event.target.value });
  };
  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      navigate("/");
    }
    countryListing();
    invoicedata();
  }, [filter.location, startDate, endDate]);
  const countryListing = async () => {
    setLoading(true);
    await axios({
      url: `${process.env.REACT_APP_SERVER_URL}/api/getLocation`,
      method: "GET",
    })
      .then((response) => {
        if (response.data.status == 1) {
          setCountyList(response?.data?.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const pageSize = 10;
  const changePage = (value) => {
    let temp = (value - 1) * pageSize;
    setPagination(temp);
    invoicedata(temp);
  };
  const ceil = Math.ceil(totalPages / pageSize);
  const invoicedata = async (temp) => {
    setLoading(true);
    let dateStart = "";
    let dateEnd = "";

    if (startDate) {
      dateStart = moment(startDate).format("YYYY-MM-DD");
    }
    if (endDate) {
      dateEnd = moment(startDate).format("YYYY-MM-DD");
    }
    await axios({
      url: `${
        process.env.REACT_APP_SERVER_URL
      }/api/invoice/list?fromDate=${dateStart}&toDate=${dateEnd}&locationId=${
        filter.location ? filter.location : 0
      }&Offset=${temp ? temp : 0}&RecordCount=${pageSize}`,
      method: "GET",
    })
      .then((response) => {
        console.log(response?.data?.data);
        if (response.data.status === 1) {
          setData(response?.data?.data);
          setTotalPages(response.data.totalRecords);
          setDataRecord(response.data.totalRecords);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  return (
    <Fragment>
      <div className="page-header">
        <h4 className="page-title mb-0">Invoice Summary</h4>
      </div>
      <div className="container mb-5" style={{ marginLeft: "-10px" }}>
        <Row>
          <Col
            xxl={8}
            lg={8}
            md={6}
            className="xm-12 d-flex justify-content-start align-items-center gap-2"
          >
            <div id="filterlocation">
              <select
                name="location"
                type="text"
                autoComplete="off"
                placeholder="Country"
                className="form-select"
                onChange={changeHandler("location")}
                value={filter.location}
                aria-label="Default select example"
              >
                <option value="" selected>
                  Location
                </option>
                {CountyList.map((country) => {
                  return (
                    <option key={country.locId} value={country.locId}>
                      {country.locName}
                    </option>
                  );
                })}
              </select>
            </div>

            <div>
              <DatePicker
                isClearable
                dateFormat="dd/MM/yyyy"
                placeholderText="From Date"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                className="form-control"
                id="exampleFormControlInput1"
              />
            </div>
            <div>
              <DatePicker
                isClearable
                dateFormat="dd/MM/yyyy"
                placeholderText="To Date"
                selected={endDate}
                selectsEnd
                endDate={endDate}
                minDate={startDate}
                onChange={(date) => setEndDate(date)}
                className="form-control"
                id="exampleFormControlInput1"
              />
            </div>
          </Col>
          <Col
            xxl={4}
            lg={4}
            md={6}
            className="xm-12 d-flex justify-content-center align-items-center px-2 gap-2"
          ></Col>
        </Row>
      </div>
      <Row>
        <Col xl={12} lg={12} md={12}>
          <Card>
            <Card.Body>
              <div className="table-responsive">
                <ResponsiveDataTable
                  data={data}
                  changePage={changePage}
                  ceil={ceil}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
}
