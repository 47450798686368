import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { toast } from "react-toastify";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import search from "../../../assets/images/search.png";
import edit from "../../../assets/images/edit.svg";
import del from "../../../assets/images/trash.svg";
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';
import Skeleton from 'react-loading-skeleton';
import Loader from '../../../utils/Loader';

function CategoryList() {
    const navigate = useNavigate();
    const schema = yup.object().shape({
      name: yup.string().required("Category Name required"),
    })
    const {
      register,
      handleSubmit,
      reset,
      setValue,
      clearErrors,
      formState: { errors },
    } = useForm({
      resolver: yupResolver(schema),
    });
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState(1);
    const [totalPages, setTotalPages] = useState();
    const pageSize = 20;
  
    useEffect(() => {
      onSubmit()
    }, [])
  
    const onSubmit = async (value) => {
      setLoading(true)
      axios({
        url: `${process.env.REACT_APP_SERVER_URL}/api/category/getcategorylist?name=${value?.name ? value?.name : ''}&Offset=${value?.offNum ? value?.offNum : 0}&RecordCount=${pageSize}`,
        method: 'GET',
      }).then((response) => {
        setLoading(false)
        setData(response.data.data)
        setTotalPages(response.data.totalRecords);
      })
        .catch((error) => {
          setLoading(false)
        })
    };
  
    const changePage = (value) => {
      let temp = (value - 1) * 20;
      setPagination(temp);
      onSubmit({ offNum: temp });
  };
  const ceil = Math.ceil(totalPages / pageSize);
    const addmore = () => {
      navigate('/manage-category')
    }
    const editfunction = (data) => {
      navigate('/manage-category', { state: { id: data } });
    }
    const deletefunction = async (id) => {
      const upload = {
        categoryId: parseInt(id),
      }
      setLoading(true)
      confirmAlert({
        title: 'Confirm to submit',
        message: 'Are you sure to do this.',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              try {
                axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/ProductCategory/DeleteProductCategoryById`, {
                  data: upload,
                }).then((response) => {
                  toast.success(response.data.message);
                  setLoading(false);
                  window.location.reload();
                }).catch((error) => {
                  setLoading(false);
                  console.log(error);
                });
              } catch (error) {
                setLoading(false);
                console.log(error);
              }
            },
          },
          {
            label: 'No',
            onClick: () => alert('Click No'),
          },
        ],
      });
    }
    const handleKeyDown = (e) => {
      // Check if the pressed key is "Enter"
      if (e.key === 'Enter') {
        // Call your submit function here
        onSubmit({ name: e.target.value });
      }
    };
  return (
    <>
    <Loader loading={loading} />
    <div className="col-xl-12">
      <div className="card custom-card">
        <div className="card-header justify-content-between">
          <div className="card-title">
            Categories
          </div>
          <div className="prism-toggle">
            <button className="btn btn-secondary-light btn-border-start" type='button' onClick={addmore}>Add New</button>
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <div className="gridjs-search mt-0 mb-3">
              <input type="search" placeholder="Type a keyword and press enter" name='name' onKeyDown={handleKeyDown}  {...register("name")} aria-label="Type a keyword..." className="gridjs-input gridjs-search-input" />
            </div>
            {loading ?
                  <Skeleton count={5} /> : 
                  <table className="gridjs-table" >
                    <thead>
                      <tr>
                        <th className="gridjs-th gridjs-th-sort" style={{ width: '50px' }}>Sl No</th>
                        <th className="gridjs-th">CategoryName</th>
                       
                        <th className="gridjs-th" style={{ width: '100px' }}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.map((contact, index) => {
                        return (
                          <tr className="gridjs-tr" key={index}>
                            <td className="gridjs-td">{index + 1}</td>
                            <td className="gridjs-td">{contact.name}</td>
                            
                            <td className="gridjs-td">
                              <div className="hstack gap-2 fs-15">
                                <button className="btn btn-icon btn-sm btn-light" style={{ marginLeft: 10, marginRight: 10 }} onClick={() => editfunction(contact?.id)} ><img src={edit} alt="Edit" /></button>
                                {/* <button className="btn btn-icon btn-sm btn-light" style={{ marginLeft: 10, marginRight: 10 }} onClick={() => deletefunction(contact?.id)}><img src={del} alt="Delete" /></button> */}
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
            }
            <div className="gridjs-footer"><div className="gridjs-pagination">
              <div className="gridjs-pages">
                <ResponsivePagination
                  current={pagination}
                  total={ceil}
                  onPageChange={(value) => changePage(value)}
                />
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  )
}

export default CategoryList;

