import React, { useState, useEffect } from "react";
import { Card, Col, Row, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { toast } from 'react-toastify';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import moment from "moment";
import { Button, InputGroup, Form } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";

export default function Home() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [mainData, setMainData] = useState([]);
  const [accountHeadData, setAccountHeadData] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [amount, setPrice] = useState('');
  const [remarks, setRemarks] = useState('');
  const navigate = useNavigate();
  const schema = yup.object().shape({
    names: yup.string().required("Category Name required"),
  })
  const [daybookDate, setDaybookDate] = useState(new Date());
  const [isVisible, setIsVisible] = useState(false);
  const categoryVisibility = (categoryVisible) => {
    setIsVisible(!categoryVisible);
  };
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  useEffect(() => {
    getDaybook(daybookDate)
    getCategoryList();
  }, [])
  const handleSelectCategory = (selectedOption) => {
    setSelectedCategory(selectedOption);
  };
  const handleSelectDate = (date) => {
    setDaybookDate(date);
    getDaybook(date);
  };
  const AddPrice = (e) => {
    setPrice(e.target.value)
  }
  const handleRemarks = (e) => {
    setRemarks(e.target.value)
  }
  const disableKeyboardInput = (event) => {
    event.preventDefault();
  };
  const getDaybook = async (date) => {
    setLoading(true);
    let data = {
      dbDate: date
    };
    try {
      let response;

      response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/DB/CreateDayBook`, data);

      if (response.data.status === 1) {
        setMainData(response.data.data);
        setAccountHeadData(response.data.data.accountHeads);
        setGroupData(response.data.data.categoryGroups);
        toast.success(response.data.message);

      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getCategoryList = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/category/getcategorylist`);
      if (response.data.status === 1) {
        setCategoryList(response.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSave = async (formData) => {
    try {

      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/DB/CreateDayBookDetail`, formData);
      if (response.data.status === 1) {
        toast.success(response.data.message);
        setShowModal(false);
        reset();
      }
    } catch (error) {
      console.error(error);
    }
  };
  const addDaybookEntry = () => {
    console.log(mainData);
    axios({
      url: `${process.env.REACT_APP_SERVER_URL}/api/DB/CreateDayBookDetail`,
      method: 'POST',
      data: {
        dbid: mainData.dbId,
        categoryId: selectedCategory?.value ?? 0,
        groupId: selectedGroup.id,
        amount: amount,
        remarks: remarks
      },
    }
    ).then((response) => {
      if (response?.data?.status === 1) {
        toast.success(response.data.message)
        getDaybook(daybookDate);
      }
      else {
        toast.error(response.data.message)
      }
    })
      .catch((error) => {
        toast.error("something went wrong")
        console.log(error);
      })
  }

  return (
    <>
      {/* <div>
        <div className="row">
          <Form.Group as={Col} md="4" controlId="validationCustom02">
            <Form.Label>Date</Form.Label>
            <div className="p-1" >
            <DatePicker dateFormat="dd/MM/yyyy"                
                selected={daybookDate}
                onChange={(date) => handleSelectDate(date)}
                selectsStart               
                className="form-control"
                id="exampleFormControlInput1"
              />
             
            </div>
          </Form.Group>
        </div>
      </div> */}
      <div className="row">
        <div className="col-xxl-12 col-xl-6">
          <div className="card custom-card">
            <div className="card-header justify-content-between">
              <div className="card-title me-1">Day Summary</div>


              <DatePicker dateFormat="dd/MM/yyyy"
                onKeyDown={disableKeyboardInput}
                onBeforeInput={(e) => {
                  e.preventDefault();
                }}
                selected={daybookDate}
                onChange={(date) => handleSelectDate(date)}
                className="btn btn-secondary btn-sm shadow-sm btn-wave waves-effect waves-light"
                id="exampleFormControlInput1"
              />

            </div>
            <div className="card-body p-0">
              <div className="p-3 border-bottom border-block-end-dashed">
                {accountHeadData?.map((contact, index) => {
                  return (
                    <div className="d-flex align-items-center justify-content-between" key={index}>
                      <div className="d-block fw-semibold">{contact.name}</div>
                      <div className="fw-semibold fs-14">{contact.amount.toFixed(2)}</div>
                    </div>
                  )
                })}
              </div>
              <div className="p-3">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="fs-15">Total :</div>
                  <div className="fw-semibold fs-16 text-dark">{mainData?.dbamount?.toFixed(2)}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {groupData?.map((group, index) => {
          return (
            <div className="col-xxl-12 col-xl-6" key={index}>
              <div className="card custom-card">
                <div className="card-header justify-content-between">
                  <div className="card-title">
                    {group.name}
                  </div>
                  <button className="btn btn-sm btn-light" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => {
                    setSelectedGroup(group);
                    setPrice('');
                    setRemarks('');
                    setSelectedCategory(null);
                    console.log(group.isSingleEntry);
                    categoryVisibility(group.isSingleEntry);
                    // setShowModal(true);
                  }}>Add {group.name}</button>
                </div>
                <div className="card-body">
                  <div className="d-flex align-items-center mb-3">
                    <h4 className="fw-bold mb-0">{group.amount.toFixed(2)}</h4>
                    {/* <div className="ms-2">
                      <span className="text-muted ms-1">No of Cash Expenses : 4</span>
                    </div> */}
                  </div>

                  <ul className="list-unstyled mb-0 pt-2 crm-deals-status">
                    {group.categories && group.categories.map((category, index) => {
                      return (
                        <li className="info" key={index}>
                          <div className="d-flex align-items-center justify-content-between">
                            <div>{category.name}</div>
                            <div className="fs-12 text-muted">{category.amount.toFixed(2)}</div>
                          </div>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </div>
          )
        })}
      </div>
      <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" style={{ 'justify-content': 'normal !important' }}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">{selectedGroup?.name}</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">

              {isVisible && <div style={{ padding: 10 }}>
                <label>Category</label>
                <Select
                  {...register("categoryId")}
                  value={selectedCategory}
                  options={categoryList.map(category => ({ value: category.id, label: category.name }))}
                  onChange={handleSelectCategory}
                />
              </div>}

              <div className="row mt-1">
                <div className="col-12">
                  <label>Amount</label>
                  <div className="input-group rounded flex-nowrap">
                    <input type="text" className="form-control form-control-sm text-center  p-3" value={amount} aria-label="amount" id="db-amount" onChange={AddPrice} />
                  </div>
                </div>
              </div>
              <div className="row mt-1">
                <div className="col-12">
                  <label>Remarks</label>
                  <div className="input-group rounded flex-nowrap">
                    <input type="text" className="form-control form-control-sm text-center w-100 p-3" value={remarks} aria-label="remarks" id="db-remarks" onChange={handleRemarks} />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" data-bs-dismiss="modal" aria-label="Close" onClick={addDaybookEntry}>Add</button>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}
