
import { Link } from "react-router-dom";
import React, { Fragment, useEffect, useState } from 'react';
import { Button, Dropdown, Form, Nav, Navbar,ListGroup  } from 'react-bootstrap';
import { ImagesData } from '../../commoncomponents/images/commonimages';
import { Scrollbar } from 'react-scrollbars-custom';
import MenuItems from "./Sidebardata";
function Dash_header() {

  //Search functionality
  const [show1, setShow1] = useState(false);
  const [InputValue, setInputValue] = useState("");
  const [show2, setShow2] = useState(false);
  const [searchcolor, setsearchcolor] = useState("text-dark");
  const [searchval, setsearchval] = useState("Type something");
  const [NavData, setNavData] = useState([]);
  
  document.querySelector(".search-result")?.classList.remove("d-none")
  document.addEventListener("click", function(){
          document.querySelector(".search-result")?.classList.add("d-none")
      });
  
  let myfunction = (inputvalue) => {

   document.querySelector(".search-result")?.classList.remove("d-none")

    let i = []
    let allElement2 = [];

    MenuItems.map(mainlevel => {
      if (mainlevel.Items) {
        setShow1(true)
        mainlevel.Items.map(sublevel => {

          if (sublevel.children) {
            sublevel.children.map(sublevel1 => {

              i.push(sublevel1)
              if (sublevel1.children) {
                sublevel1.children.map(sublevel2 => {

                  i.push(sublevel2)
                  return sublevel2;
                })
              }
              return sublevel1;
            })
          }
          return sublevel;
        })
      }
      return mainlevel;
    }
    )
    for (let allElement of i) {
      if (allElement.title.toLowerCase().includes(inputvalue.toLowerCase())) {
        if (allElement.title.toLowerCase().startsWith(inputvalue.toLowerCase())) {
          setShow2(true)
          allElement2.push(allElement)
        }
      }
    }
    if (!allElement2.length || inputvalue === "") {
      if ({ inputvalue } + '/' === "") {
        setShow2(false);
        setsearchval("Type something")
        setsearchcolor('text-dark')
      }
      if (!allElement2.length) {
        setShow2(false);
        setsearchcolor('text-danger')
        setsearchval("There is no component with this name")
      }
    }
    setNavData(allElement2)

  }

  // Darkmode
  const DarkMode = () => {
    if (document.querySelector("body")?.classList.contains('dark-mode')) {
      document.querySelector("body")?.classList.remove('dark-mode');
      
      localStorage.setItem("Azealightmode" , "true");
      localStorage.removeItem("Azeadarkmode");
  
      let myonoffswitch2 = document.querySelector("#myonoffswitch1");
      if (myonoffswitch2) {
        myonoffswitch2.checked = true;
      }
      let myonoffswitch5 = document.querySelector("#myonoffswitch3");
      if (myonoffswitch2) {
        myonoffswitch5.checked = true;
      }
      let myonoffswitch8 = document.querySelector("#myonoffswitch6");
      if (myonoffswitch2) {
        myonoffswitch8.checked = true;
      }
    }
    else {
      document.querySelector("body")?.classList.add('dark-mode');
      localStorage.setItem("Azeadarkmode" , "true");
      localStorage.removeItem("Azealightmode");
      let myonoffswitch8 = document.querySelector("#myonoffswitch8")
      if (myonoffswitch8) {
        myonoffswitch8.checked = true;
      }
      let myonoffswitch5 = document.querySelector("#myonoffswitch5")
      if (myonoffswitch5) {
        myonoffswitch5.checked = true;
      }
      let myonoffswitch2 = document.querySelector("#myonoffswitch2")
      if (myonoffswitch2) {
        myonoffswitch2.checked = true;
      }
    }
  };

  // FullScreen
  const elem = document.documentElement;
  let i = true;
  const Fullscreen = (vale) => {
    switch (vale) {
      case true:
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) { /* Safari */
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE11 */
          elem.msRequestFullscreen();
        }
        i = false;
        break;
      case false:
        document.exitFullscreen();
        i = true;
        break;
    }
  };

  //Switcher Icon

  const SwitcherIcon = () => {
    document.querySelector(".demo_changer")?.classList.add("active");
    let demoChanger = document.querySelector(".demo_changer") 
    demoChanger.style.right = "0px";
  }
  //leftsidemenu
  const openSidebar = () => {
    document.querySelector("body")?.classList.toggle("sidenav-toggled");
  };
  // responsivesearch
  const responsivesearch = () => {
    document.querySelector(".header-search")?.classList.toggle("show");
  };
  return (
    <Fragment>
      <div className="app-header header">
        <div className="container-fluid main-container">
          <div className="d-flex">
            <div className="app-sidebar__toggle d-flex align-items-center" data-bs-toggle="sidebar">
              <div className="open-toggle" to="#"
                onClick={() => {
                  openSidebar();
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="feather feather-align-left header-icon"
                  width="24" height="24" viewBox="0 0 24 24">
                  <path d="M4 6h16v2H4zm0 5h16v2H4zm0 5h16v2H4z"></path>
                </svg>
              </div>
            </div>
            {/* <div className="logo-horizontal">
              <Link to={'/'}>
                <img src={ImagesData('logo')} className="header-brand-img desktop-lgo"
                  alt="Azea logo" />
                <img src={ImagesData('logo1')} className="header-brand-img dark-logo "
                  alt="Azea logo" />
              </Link>
            </div> */}
            <div className="main-header-center ms-3 d-none d-lg-block">
              <div className="form-inline">
                <div className="search-element">
                  {/* <Form.Control type="text" defaultValue={InputValue} id="typehead" placeholder="Search for results..."
                    autoComplete="off" onChange={(ele => { myfunction(ele.target.value); setInputValue(ele.target.value) })} />
                  <Button variant='' className="btn px-2 ">	<svg xmlns="http://www.w3.org/2000/svg" className="header-icon search-icon" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M10 18a7.952 7.952 0 0 0 4.897-1.688l4.396 4.396 1.414-1.414-4.396-4.396A7.952 7.952 0 0 0 18 10c0-4.411-3.589-8-8-8s-8 3.589-8 8 3.589 8 8 8zm0-14c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6z">
                    </path>
                  </svg></Button> */}
                </div>
              </div>
              {show1 ?
                <div className="card search-result position-absolute z-index-9 search-fix  border mt-1">
                  <div className="card-header">
                    <h4 className="card-title me-2 text-break">Search result of {InputValue}</h4>
                  </div>
                  <ListGroup className='mt-2 search_bar'>
                    {show2 ?
                      NavData.map((e) =>
                        <ListGroup.Item key={Math.random()} className="">
                          <Link to={'/'} className='search-result-item'>{e.title}</Link>
                        </ListGroup.Item>
                      )
                      : <b className={`${searchcolor} `}>{searchval}</b>}
                  </ListGroup>

                </div>
                : ""}
            </div>
            <Navbar className="d-flex order-lg-2 ms-auto main-header-end p-0" expand="lg" style={{height:50,justifyContent:'center',alignItems:'center'}}>
              <Navbar.Toggle className=" navresponsive-toggler d-lg-none" type="button"
                data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent-4"
                aria-controls="navbarSupportedContent-4"
                aria-expanded="true"
                aria-label="Toggle navigation">
                <span className="navbar-toggler-icon header-iocns fe fe-more-vertical mt-1"></span>
              </Navbar.Toggle>


              <div className="mb-0 navbar navbar-expand-lg navbar-nav-right responsive-navbar navbar-dark p-0" >
                <Navbar.Collapse className="collapse navbar-collapse "
                  id="navbarSupportedContent-4" >
                  <div className="d-flex order-lg-2">
                   
                    {/* <div className="dropdown theme-setting">
                      <a className="nav-link icon theme-layout nav-link-bg layout-setting" onClick={() => DarkMode()}>
                        <span className="light-layout"><svg xmlns="http://www.w3.org/2000/svg"
                          className="header-icon" width="24" height="24" viewBox="0 0 24 24">
                          <path d="M20.742 13.045a8.088 8.088 0 0 1-2.077.271c-2.135 0-4.14-.83-5.646-2.336a8.025 8.025 0 0 1-2.064-7.723A1 1 0 0 0 9.73 2.034a10.014 10.014 0 0 0-4.489 2.582c-3.898 3.898-3.898 10.243 0 14.143a9.937 9.937 0 0 0 7.072 2.93 9.93 9.93 0 0 0 7.07-2.929 10.007 10.007 0 0 0 2.583-4.491 1.001 1.001 0 0 0-1.224-1.224zm-2.772 4.301a7.947 7.947 0 0 1-5.656 2.343 7.953 7.953 0 0 1-5.658-2.344c-3.118-3.119-3.118-8.195 0-11.314a7.923 7.923 0 0 1 2.06-1.483 10.027 10.027 0 0 0 2.89 7.848 9.972 9.972 0 0 0 7.848 2.891 8.036 8.036 0 0 1-1.484 2.059z">
                          </path>
                        </svg></span>
                        <span className="dark-layout"><svg xmlns="http://www.w3.org/2000/svg"
                          className="header-icon" width="24" height="24" viewBox="0 0 24 24">
                          <path d="M6.993 12c0 2.761 2.246 5.007 5.007 5.007s5.007-2.246 5.007-5.007S14.761 6.993 12 6.993 6.993 9.239 6.993 12zM12 8.993c1.658 0 3.007 1.349 3.007 3.007S13.658 15.007 12 15.007 8.993 13.658 8.993 12 10.342 8.993 12 8.993zM10.998 19h2v3h-2zm0-17h2v3h-2zm-9 9h3v2h-3zm17 0h3v2h-3zM4.219 18.363l2.12-2.122 1.415 1.414-2.12 2.122zM16.24 6.344l2.122-2.122 1.414 1.414-2.122 2.122zM6.342 7.759 4.22 5.637l1.415-1.414 2.12 2.122zm13.434 10.605-1.414 1.414-2.122-2.122 1.414-1.414z">
                          </path>
                        </svg></span>
                      </a>
                    </div> */}

                    <div className="dropdown  header-fullscreen d-flex ">
                      <a className="nav-link icon full-screen-link p-0" id="fullscreen-button" onClick={() => Fullscreen(i)}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="header-icon" width="24"
                          height="24" viewBox="0 0 24 24">
                          <path
                            d="M5 5h5V3H3v7h2zm5 14H5v-5H3v7h7zm11-5h-2v5h-5v2h7zm-2-4h2V3h-7v2h5z">
                          </path>
                        </svg>
                      </a>
                    </div>
                   

                    <Dropdown className="profile-dropdown d-flex">
                      <Dropdown.Toggle as="a" className="nav-link pe-0 leading-none no-caret" >
                        <span className="header-avatar1">
                          <img src={ImagesData("users2")} alt="img"
                            className="avatar avatar-md brround" />
                        </span>
                      </Dropdown.Toggle>

                      <Dropdown.Menu className=" dropdown-menu-end dropdown-menu-arrow animated">
                        <div className="text-center">
                          <div className="text-center user pb-0 font-weight-bold">Admin
                          </div>
                          <span className="text-center user-semi-title">Admin
                            </span>
                          <div className="dropdown-divider"></div>
                        </div>
                        <Dropdown.Item className="text-center user pb-0 font-weight-bold" href="#/action-1"> </Dropdown.Item>
                       
                        <Link className="dropdown-item d-flex" to={'/'}>
                          <svg className="header-icon me-2" xmlns="http://www.w3.org/2000/svg"
                            enableBackground="new 0 0 24 24" height="24"
                            viewBox="0 0 24 24" width="24">
                            <g>
                              <rect fill="none" height="24" width="24"></rect>
                            </g>
                            <g>
                              <path
                                d="M11,7L9.6,8.4l2.6,2.6H2v2h10.2l-2.6,2.6L11,17l5-5L11,7z M20,19h-8v2h8c1.1,0,2-0.9,2-2V5c0-1.1-0.9-2-2-2h-8v2h8V19z">
                              </path>
                            </g>
                          </svg>Sign Out</Link>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                </Navbar.Collapse>

              </div>
            </Navbar>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Dash_header;
