import React, { Fragment, useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import store from './redux/store';
import { Provider } from 'react-redux';
import Dash_header from './Components/Common/Dash_header';
import Dash_sidebar from './Components/Common/Dash_sidebar';
import Dash_footer from './Components/Common/Dash_footer';

function Main() {

  document.querySelector("body")?.classList.remove("landing-page" ,"horizontal");
  document.querySelector("body")?.classList.remove("h-100vh" ,"bg-primary", "login-page");
  document.querySelector("body")?.classList.remove("h-100vh" ,"bg-light", "login-page");
  document.body.classList.remove("register1", "login-page");
  document.body.classList.remove("register-2", "login-page");
  document.body.classList.remove("bg-white","register-3", "login-page");
  document.body.classList.remove("comming", "login-page");
  document.body.classList.remove("construction", "login-page");
  
// Switcher
 const SwitcherMenuclose = () => {
  // document.querySelector(".demo_changer")?.classList.remove("active");
  // let demo_changer = document.querySelector(".demo_changer") 
  //   demo_changer.style.right = "-270px";
  }
  //
  const Sideabr = () => {
    document.querySelector("body")?.classList.remove("sidenav-toggled");
  }
  let [MyclassName , setMyClass] = useState("")

	const Bodyclickk = () => {
		if (localStorage.getItem("Syntoverticalstyles") == "icontext") {
			setMyClass("")
		}
	}
	useEffect(() => {
		import("preline");

	}, []);
  return (
<Fragment>
<Provider store={store}>
    <div className='horizontalMenucontainer'>
      <div className='page'>
        <div className='page-main'>
            <Dash_header/>
            <Dash_sidebar/>
          <div className='jumps-prevent' style={{ paddingTop: "0px" }}></div>
          <div className='app-content main-content' onClick = {() => {
          SwitcherMenuclose();
          Sideabr();
          }}>
            <div className='side-app'>
              <div className='main-container container-fluid px-0'>
              <Outlet/>
              </div>
            </div>
          </div>
        
        </div>
        {/* <Dash_footer/> */}
      </div>
    </div>
    </Provider>
    </Fragment>
  )
}

export default Main;

