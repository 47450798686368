import React, { Fragment } from 'react'
import Main_dashboard from '../Components/dashboard/Main_dashboard';
import DataTable from '../Components/list/datatable';
import View_invoice from '../Components/dashboard/View_invoice';
import Home from '../pages/Home';
import Category from '../Components/master/category/CategoryList';
import ManageCategory from '../Components/master/category/AddCategory';
import CategoryGroup from '../Components/master/categorygroup/CategoryGroupList';
import MangeCategoryGroup from '../Components/master/categorygroup/CategoryGroup';
import ManageAccountHead  from '../Components/master/accounthead/AccountHead';
import AccountHead from '../Components/master/accounthead/AccountHeadList';



export const RouterData = [

    { path: `dashboard`, element: <Main_dashboard /> },
    { path: `invoice`, element: <View_invoice /> },
    { path: `home`, element: <Home /> },
    { path: `category`, element: <Category /> },
    { path: `manage-category`, element: <ManageCategory /> },
    { path: `category-group`, element: <CategoryGroup /> },
    { path: `manage-category-group`, element: <MangeCategoryGroup /> },
    { path: `account-head`, element: <AccountHead /> },
    { path: `manage-account-head`, element: <ManageAccountHead /> },

];