import React from "react";
import {Doughnut } from "react-chartjs-2";
export default function Doughnutgraphs({data}) {
  
  return (
    <>
      <div className="chart-wrapper">
        <div id="spark1">
          <Doughnut 
          data={{
            labels: data?.locationOrders?.map((data) => data.location),
            datasets: [
              {
                label: "Order",
                data: data?.locationOrders?.map((data) => data.orders),
                backgroundColor:data?.locationOrders?.map((data) => data?.color)
                ,
                borderColor:data?.locationOrders?.map((data) => data?.color)
              },
            ],
          }}
          options={{
            plugins: {
              title: {
                text: "Location Wise Orders",
              },
            },
          }}
        />        
        </div>
      </div>
    </>
  );
}
