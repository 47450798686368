import React, { FC, Fragment, useState } from "react";
import {
  Button,
  Card,
  Col,
  InputGroup,
  Row,
  Form,
  Alert,
  Nav,
  Tab,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { ImagesData } from "../commoncomponents/images/commonimages";
import logo from "../assets/images/bjf.png";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useSearchParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { Oval } from "react-loader-spinner";


export default function Login() {
    document.title = "Daybook"
  document.body.classList.add("register-2", "login-page");
  const [Loader, setLoader] = useState(false);
  const [passwordshow, setpasswordshow] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const usernameRegex =
    /^(?:\d{10}|[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
  const passwordRegex = /^(?=.*[^a-zA-Z0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/;
  const schema = yup.object().shape({
    UserName: yup.string().required("Username required"),
    Password: yup
        .string()
        .required("Password required")
        .min(7, "Passwords must be 7 characters or more")
        .max(20, "Max characters limit Reached")
        .matches(
            passwordRegex,
            "Password must contain at least one uppercase letter, one lowercase letter, one number and one special character"
        ),
    Password: yup.string().required("Password required"),
  });
  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    setLoading(true);
    axios({
      url: `${process.env.REACT_APP_SERVER_URL}/api/User/login`,
      method: "POST",
      data: {
        Username: data.UserName,
        Password: data.Password,
        device_token: "",
      },
      noAccesstoken: true,
    })
      .then((response) => {
        if (response.data.status === 1) {
          setLoading(false);         
          localStorage.setItem("accessToken", response.data.token);
          localStorage.setItem("profile", JSON.stringify(response.data));
          Swal.fire({
            icon: "success",
            text: "Admin has Logged in Successfully",
            showConfirmButton: false,
            timer: 3000,
          });
          navigate("/Home");
        } else {
          setLoading(false);
          Swal.fire({
            icon: "error",
            text: "Login Failed. Username/Password entered is invalid",
            confirmButtonColor: "#12C412",
          });
          // toast.warning("Login Failed. Username/Password entered is invalid")
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  return (
    <Fragment>
      <div className="page">
        <div className="page-content">
          <div className="container">
            <Row>
              <div className="col mx-auto mt-5">
                <div className="row justify-content-center">
                  <Col
                    md={7}
                    lg={5}
                    xl={4}
                    className="col-md-7 col-lg-5 col-xl-4"
                  >
                    <Card>
                      <Tab.Container
                        id="left-tabs-example "
                        defaultActiveKey="react"
                      >
                        <Nav
                          className="justify-content-center"
                          style={{ backgroundColor: "white" }}
                        >
                          <Nav.Item>
                            <Nav.Link eventKey="react">
                              <img
                                src={logo}
                                alt="logo2"
                                style={{ width: 200 }}
                              />
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                        <Tab.Content>
                          <Tab.Pane eventKey="react">
                            <Row>
                              <Col xl={12} md={12}>
                                <Card className="mb-0">
                                  <Card.Body>
                                    <div className="text-center mb-3">
                                      <h1 className="mb-2">Log In</h1>
                                      <Link to="#">Welcome Back !</Link>
                                    </div>
                                    <Form
                                      className="mt-5"
                                      onSubmit={handleSubmit(onSubmit)}
                                    >
                                      <InputGroup className="mb-4">
                                        <InputGroup.Text>
                                          <i className="fe fe-user"></i>
                                        </InputGroup.Text>
                                        <Form.Control
                                          type="text"
                                          className=""
                                          name="UserName"
                                          {...register("UserName")}
                                          placeholder="Username"
                                        />
                                      </InputGroup>
                                      <p className="errorMsg pl-3 mt-2">
                                        {errors.UserName
                                          ? errors.UserName.message
                                          : ""}
                                      </p>
                                      <InputGroup className="input-group mb-4">
                                        <div
                                          className="input-group"
                                          id="Password-toggle1"
                                        >
                                          <Link
                                            className="input-group-text"
                                            onClick={() =>
                                              setpasswordshow(!passwordshow)
                                            }
                                          >
                                            <i
                                              className={`zmdi ${
                                                passwordshow
                                                  ? "zmdi-eye"
                                                  : "zmdi-eye-off"
                                              } text-muted`}
                                              aria-hidden="true"
                                            ></i>
                                          </Link>
                                          <Form.Control
                                            className=""
                                            name="Password"
                                            {...register("Password")}
                                            type={
                                              passwordshow ? "text" : "password"
                                            }
                                            placeholder="Confirm Password"
                                          />
                                        </div>
                                      </InputGroup>
                                      <p className="errorMsg pl-3 mt-2">
                                        {errors.Password
                                          ? errors.Password.message
                                          : ""}
                                      </p>
                                      <Form.Group className="form-group fs-13 text-center">
                                        <Link> Forget Password ?</Link>
                                      </Form.Group>
                                      <Form.Group className="form-group text-center mb-3">
                                        <button
                                          type="submit"
                                          className="btn  w-100 br-7 btn-primary"
                                        >
                                          {" "}
                                          {loading ? (
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                height: "25px",
                                              }}
                                            >
                                              <Oval
                                                heigth="20"
                                                width="20"
                                                color="#12C412"
                                                arialLabel="loading"
                                              />
                                            </div>
                                          ) : (
                                            "Login"
                                          )}
                                        </button>
                                      </Form.Group>
                                      <Form.Group className="form-group fs-13 text-center">
                                        <Link to={"/register"}>
                                          {" "}
                                          Don't have an account yet? Sign Up
                                        </Link>
                                      </Form.Group>
                                    </Form>
                                  </Card.Body>
                                </Card>
                              </Col>
                            </Row>
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </Card>
                  </Col>
                </div>
              </div>
            </Row>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
