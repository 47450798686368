import React from "react";
import { Bar } from "react-chartjs-2";
export default function BarChart({ data }) {
  const datas = {
    labels: data?.locationWiseSales?.map((data) => data.location),
    datasets: [
      {
        axis: "y",
        label: "Sales",
        backgroundColor: data?.locationWiseSales?.map((data) => data?.color),
        borderColor: data?.locationWiseSales?.map((data) => data?.color),
        borderWidth: 1,
        // hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        // hoverBorderColor: 'rgba(255,99,132,1)',
        data: data?.locationWiseSales?.map((data) => data.amount),
      },
      {
        axis: "y",
        label: "Orders",
        backgroundColor: data?.locationOrders?.map((data) => data?.color),
        borderColor: data?.locationOrders?.map((data) => data?.color),
        backgroundColor: "rgba(78, 148, 87, 0.8)",
        borderColor: "rgba(60, 106, 66, 0.8)",
        borderWidth: 1,
        // hoverBackgroundColor: 'rgba(92, 155, 101, 0.8)',
        // hoverBorderColor: 'rgba(48, 82, 53, 0.8)',
        data: data?.locationOrders?.map((data) => data.orders),
      },
    ],
  };

  return (
    <>
      <div className="chart-wrapper">
        <div id="spark2">
          <Bar
            data={datas}
            options={{
              indexAxis: "y",
              plugins: {
                title: {
                  display: false,
                  text: "currText",
                  font: { size: 12, family: "rubik" },
                },
                legend: { display: false, position: "right" },
              },
              maintainAspectRatio: false,
            }}
          />
        </div>
      </div>
    </>
  );
}
