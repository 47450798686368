import React from "react";
import { Pie } from "react-chartjs-2";
export default function Piegraphs({data}) {
  return (
    <>
      <div className="chart-wrapper">
        <div id="spark2">
          <Pie
            data={{
              labels: data?.locationWiseSales?.map((data) => data?.location),
           
             
              datasets: [
                {
                  label: "Sales",
                  data: data?.locationWiseSales?.map((data) => data?.amount),
                  backgroundColor:data?.locationWiseSales?.map((data) => data?.color),
                  borderColor:data?.locationWiseSales?.map((data) => data?.color)
                },
              ],
            }}
            options={{
              plugins: {
                title: {
                  text: "Location Wise Sales",
                },
              },
            }}
          />
        </div>
      </div>
    </>
  );
}
