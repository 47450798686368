import React from 'react';

const MenuItems = [
    {
        menutitle: "Home",
        id: 1,
        Items: [
            {
                path: `home`,
                // icon: (<svg xmlns="http://www.w3.org/2000/svg" className="side-menu__icon" width="24" height="24" viewBox="0 0 24 24"><path d="M3 13h1v7c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-7h1a1 1 0 0 0 .707-1.707l-9-9a.999.999 0 0 0-1.414 0l-9 9A1 1 0 0 0 3 13zm7 7v-5h4v5h-4zm2-15.586 6 6V15l.001 5H16v-5c0-1.103-.897-2-2-2h-4c-1.103 0-2 .897-2 2v5H6v-9.586l6-6z" /></svg>),
                type: 'link',
                active: false,
                selected: false,
                title: 'Home'
            },
        ]
    },
    {
        menutitle: "Main",
        id: 2,
        Items: [
            {
                path: `Masters`,
                icon: (<svg width="20"
                height="20" className="side-menu__icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                <path stroke="currentColor" strokeLinecap="round" stroke-linejoin="round" stroke-width="2" d="M11 14h6m-3 3v-6M1.857 1h4.286c.473 0 .857.384.857.857v4.286A.857.857 0 0 1 6.143 7H1.857A.857.857 0 0 1 1 6.143V1.857C1 1.384 1.384 1 1.857 1Zm10 0h4.286c.473 0 .857.384.857.857v4.286a.857.857 0 0 1-.857.857h-4.286A.857.857 0 0 1 11 6.143V1.857c0-.473.384-.857.857-.857Zm-10 10h4.286c.473 0 .857.384.857.857v4.286a.857.857 0 0 1-.857.857H1.857A.857.857 0 0 1 1 16.143v-4.286c0-.473.384-.857.857-.857Z"/>
              </svg>),
                type: 'sub',
                active: false,
                selected: false,
                title: 'Masters',
                children: [
                    { path: `category`, type: 'link', active: false, selected: false, title: 'Category' },
                    { path: `category-group`, type: 'link', active: false, selected: false, title: 'Category Group' },
                    { path: `account-head`, type: 'link', active: false, selected: false, title: 'Account Head' },
                   
                   
                ],
            },

           
            // {
            //     path: `MR-list`,
            //     icon: (<svg width="20"
            //     height="20" className="side-menu__icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
            //     <path stroke="currentColor" strokeLinecap="round" stroke-linejoin="round" stroke-width="2" d="M5 1v3m5-3v3m5-3v3M1 7h7m1.506 3.429 2.065 2.065M19 7h-2M2 3h16a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1Zm6 13H6v-2l5.227-5.292a1.46 1.46 0 0 1 2.065 2.065L8 16Z"/>
            //   </svg>),
            //     type: 'sub',
            //     active: false,
            //     selected: false,
            //     title: 'MR',
            //     children: [
            //         // { path: `MR`, type: 'link', active: false, selected: false, title: 'MR' },
            //         { path: `MR-list`, type: 'link', active: false, selected: false, title: 'List' },
            //         { path: `MR`, type: 'link', active: false, selected: false, title: 'Add MR' },
            //     ],
            // },
            // {
            //     path: `PO-list`,
            //     icon: (<svg width="20"
            //     height="20" className="side-menu__icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 20">
            //     <path stroke="currentColor" strokeLinecap="round" stroke-linejoin="round" stroke-width="2" d="M8 5h4m-2 2V3M6 15a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm0 0h8m-8 0-1-4m9 4a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm.938-11H17l-2 7H5m0 0L3 4m0 0h2M3 4l-.792-3H1"/>
            //   </svg>),
            //     type: 'sub',
            //     active: false,
            //     selected: false,
            //     title: 'PO',
            //     children: [
            //         // { path: `MR`, type: 'link', active: false, selected: false, title: 'MR' },
            //         { path: `PO-list`, type: 'link', active: false, selected: false, title: 'List' },
            //         { path: `PO`, type: 'link', active: false, selected: false, title: 'Add PO' },
            //     ],
            // },
            // {
            //     path: `srv-list`,
            //     icon: (<svg width="20"
            //     height="20" className="side-menu__icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 20">
            //     <path stroke="currentColor" strokeLinecap="round" stroke-linejoin="round" stroke-width="2" d="m13 19-6-5-6 5V2a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v17Z"/>
            //   </svg>),
            //     type: 'sub',
            //     active: false,
            //     selected: false,
            //     title: 'SRV',
            //     children: [
            //         // { path: `MR`, type: 'link', active: false, selected: false, title: 'MR' },
            //         { path: `srv-list`, type: 'link', active: false, selected: false, title: 'List' },
            //         { path: `add-srv`, type: 'link', active: false, selected: false, title: 'Add SRV' },
            //     ],
            // },
            // {
            //     path: `order-details`,
            //     icon: (<svg xmlns="http://www.w3.org/2000/svg" className="side-menu__icon" width="24"
            //         height="24" viewBox="0 0 24 24">
            //         <path d="M20 17V7c0-2.168-3.663-4-8-4S4 4.832 4 7v10c0 2.168 3.663 4 8 4s8-1.832 8-4zM12 5c3.691 0 5.931 1.507 6 1.994C17.931 7.493 15.691 9 12 9S6.069 7.493 6 7.006C6.069 6.507 8.309 5 12 5zM6 9.607C7.479 10.454 9.637 11 12 11s4.521-.546 6-1.393v2.387c-.069.499-2.309 2.006-6 2.006s-5.931-1.507-6-2V9.607zM6 17v-2.393C7.479 15.454 9.637 16 12 16s4.521-.546 6-1.393v2.387c-.069.499-2.309 2.006-6 2.006s-5.931-1.507-6-2z" /></svg>),
            //     type: 'link',
            //     active: false,
            //     selected: false,
            //     title: 'Order Details',
            // },
           
        ],
    },
];
export default MenuItems;

