import React, { Fragment, useState, useEffect } from 'react';
import { Button, Card, Col, InputGroup, Row, Form, Alert, Nav, Tab } from 'react-bootstrap';

import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Oval } from 'react-loader-spinner';
import { useLocation, useSearchParams } from "react-router-dom";

function AccountHead() {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const isEditMode = location.state?.id > 0;

    const schema = yup.object().shape({
        name: yup.string().required('Name required'),
    });

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        console.log(isEditMode);
        if (isEditMode) {
            axios({
                url: `${process.env.REACT_APP_SERVER_URL}/api/AccountHead/GetAccountHeadById?id=${parseInt(location.state.id)}`,
                method: 'GET',
            }).then((response) => {
                setLoading(false)
                setValue("name", response.data.data.name)
            })
                .catch((error) => {
                    setLoading(false)
                })

        }
    }, [isEditMode]);
    const handleCancel = () => {
        // Redirect to the desired page
        navigate('/account-head');
      };
    const onSubmit = async (data) => {
        setLoading(true);
        console.log('onsub')
        try {
            let response;
            if (isEditMode) {
                data.id = location.state.id;
                response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/AccountHead/UpdateAccountHead`, data);
            } else {
                data.id = 0;
                response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/AccountHead/CreateAccountHead`, data);
            }
            if (response.data.status === 1) {
                toast.success(response.data.message);
                navigate('/account-head');
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Fragment>
            <div className="col-xl-6">
            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="card custom-card">
                    <div className="card-header justify-content-between">
                        <div className="card-title">
                            {isEditMode ? 'Edit AccountHead' : 'Add AccountHead'}
                        </div>
                    </div>
                    <div className="card-body">
                       
                            <Form.Group className="mb-3" controlId="form-text">
                                <Form.Label className="form-label fs-14 text-dark">AccountHead Name</Form.Label>
                                <Form.Control type="text" placeholder="Name" {...register('name')} />
                            </Form.Group>

                           
                            
                        
                    </div>
                    <div className="card-footer border-top-1 justify-content-between">
                    <Button type="submit" disabled={loading}>
                                {loading ? (
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '25px' }}>
                                        <Oval heigth="20" width="20" color="#12C412" arialLabel="loading" />
                                    </div>) : (isEditMode ? 'Update' : 'Save')}
                            </Button>
                            <button onClick={handleCancel} className="btn btn-danger btn-raised-shadow btn-wave waves-effect waves-light">Cancel</button>
                    </div>
                </div>
                </Form>
            </div>
        </Fragment>
    );
}

export default AccountHead;
