import React, { useState, useEffect } from "react";
import { Button, Table, Col, Row, Card } from "react-bootstrap";
import { Pagination } from "@mui/material";
import "react-responsive-modal/styles.css";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
export const ResponsiveDataTable = ({ data, changePage = () => {}, ceil }) => {
  const [invDetals, setIncDetails] = useState();
  const handlemodal = (value) => {
    console.log("modaldata",value)
    setIncDetails(value);
  };
  return (
    <>
        <div id="myModal" class="modal fade" role="dialog">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal">
              <FontAwesomeIcon
              icon={faClose}
              id="modal-close"
              style={{ marginRight: "5px", cursor: "pointer",color:"#ffff",background:"red" }}
            />
              </button>
            </div>
            <div class="modal-body" style={{ background: "#f1f0f0" }}>
              <div className="modaltop-container">
                <div className="Container">
                  <div className="modal-bodycontainer" id="modalinvoice">
                  <div className="top-modal-container">
                    <Row className="mt-2">
                      <Col xl={6} lg={6} md={6}>
                        <div class="form-group d-flex alignItems-center">
                          <label for="exampleFormControlInput1">Inv No #</label>
                          <input
                            type="text"
                            value={invDetals?.invNo}
                            className="form-control ml-2"
                            id="exampleFormControlInput1"
                            placeholder=""
                          />
                        </div>
                      </Col>
                      <Col xl={6} lg={6} md={6}>
                        <div class="form-group">
                          <button type="button" className="btn">
                            Walk In
                          </button>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={6} lg={6} md={6}>
                        <div class="form-group d-flex alignItems-center">
                          <label for="exampleFormControlInput1">Inv Date</label>
                          <input
                            type="date"
                            className="form-control ml-2"
                            id="exampleFormControlInput1"
                            value={moment(invDetals?.invDate).format("YYYY-MM-DD")}
                            placeholder=""
                          />
                        </div>
                      </Col>
                      <Col xl={6} lg={6} md={6}>
                        <div className="form-group d-flex alignItems-center">
                          <label for="exampleFormControlInput1">Inv Time</label>
                          <input
                            type="text"
                            className="form-control ml-2"
                            id="exampleFormControlInput1"
                            value={invDetals?.invTime}
                            placeholder=""
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="table-responsive text-center">
                          <Table className="table-bordered text-nowrap">
                            <thead>
                              <tr>
                                <th
                                  className="text-left"
                                  style={{
                                    fontWeight: "800",
                                    color: "#757575",
                                  }}
                                >
                                  Item Name
                                </th>
                                <th
                                  className="text-left"
                                  style={{
                                    fontWeight: "800",
                                    color: "#757575",
                                  }}
                                >
                                  Qty
                                </th>
                                <th
                                  className="text-left"
                                  style={{
                                    fontWeight: "800",
                                    color: "#757575",
                                  }}
                                >
                                  Rate
                                </th>
                                <th
                                  className="text-left"
                                  style={{
                                    fontWeight: "800",
                                    color: "#757575",
                                  }}
                                >
                                  Total
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {invDetals?.invoiceDetail?.map((Product1, index) => (
                                <tr key={Math.random()}>
                                  <td className="text-left">
                                    {Product1?.itemName}
                                  </td>
                                  <td className="text-left">{Product1?.qty}</td>
                                  <td className="text-left">
                                    {Product1?.netAmt}
                                  </td>
                                  <td
                                    className="text-right"
                                    style={{ textAlign: "right" }}
                                  >
                                    {Product1?.totAmt}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    </Row>
                    </div>
                    <div className="botton-modal-container">
                    <Row className="mt-2">
                    <Col xl={6} lg={6} md={6}>
                      <div class="form-group">
                        <button type="button" className="btn">
                          Location:{invDetals?.locName}
                        </button>
                      </div>
                    </Col>
                    <Col xl={6} lg={6} md={6}>
                      <div class="form-group d-flex">
                        <Row>
                          <Col
                            xl={6}
                            lg={6}
                            md={6}
                            style={{ textAlign: "right" }}
                          >
                            <label for="exampleFormControlInput1">
                              Total Amt
                            </label>
                          </Col>
                          <Col xl={6} lg={6} md={6}>
                            <input
                              type="text"
                              className="form-control"
                              id="exampleFormControlInput1"
                              placeholder=""
                              value={invDetals?.totalAmount}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={6} lg={6} md={6}></Col>
                    <Col xl={6} lg={6} md={6}>
                    <div class="form-group d-flex">
                      <Row>
                        <Col
                          xl={6}
                          lg={6}
                          md={6}
                          style={{ textAlign: "right" }}
                        >
                          <label for="exampleFormControlInput1">
                            Invoice Disc
                          </label>
                        </Col>
                        <Col xl={6} lg={6} md={6}>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder=""
                            style={{marginLeft:"-5px"}}
                            value={invDetals?.discount}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  </Row>
                  <Row>
                    <Col xl={6} lg={6} md={6}>
                      <div className="table-responsive text-center">
                        <Table className="table-bordered text-nowrap">
                          <thead>
                            <tr>
                              <th
                                className="text-left"
                                style={{
                                  fontWeight: "800",
                                  color: "#757575",
                                }}
                              >
                                #
                              </th>
                              <th
                                className="text-left"
                                style={{
                                  fontWeight: "800",
                                  color: "#757575",
                                }}
                              >
                                Payment Mode
                              </th>
                              <th
                                className="text-left"
                                style={{
                                  fontWeight: "800",
                                  color: "#757575",
                                }}
                              >
                                Paid
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                              <tr key={Math.random()}>
                                <td className="text-left">{"1"}</td>
                                <td className="text-left">{invDetals?.paymentMode}</td>
                                <td
                                  className="text-right"
                                  style={{ textAlign: "right" }}
                                >
                                  {invDetals?.netAmt}
                                </td>
                              </tr>
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                    <Col xl={6} lg={6} md={6}>
                      <div class="form-group d-flex">
                        <Row>
                          <Col
                            xl={6}
                            lg={6}
                            md={6}
                            style={{ textAlign: "right" }}
                          >
                            <label for="exampleFormControlInput1">
                              Total Amt
                            </label>
                          </Col>
                          <Col xl={6} lg={6} md={6}>
                            <input
                              type="text"
                              className="form-control"
                              id="exampleFormControlInput1"
                              placeholder=""
                              style={{background:"#4cd964"}}
                              value={invDetals?.totalAmount}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                    </div>
                  
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                className="btn btn-outline-success"
                
              >
                Reprint
              </button>
            </div>
          </div>
        </div>
      </div>
    
   
      <div className="e-table pb-5">
        <div className="table-responsive text-center">
          <Table className="table-bordered text-nowrap">
            <thead>
              <tr>
                <th
                  className="text-left"
                  style={{ fontWeight: "800", color: "#7373dd" }}
                >
                  SI.No
                </th>
                <th
                  className="text-left"
                  style={{ fontWeight: "800", color: "#7373dd" }}
                >
                  Invoice No
                </th>
                <th
                  className="text-left"
                  style={{ fontWeight: "800", color: "#7373dd" }}
                >
                  Location Name
                </th>
                <th
                  className="text-left"
                  style={{ fontWeight: "800", color: "#7373dd" }}
                >
                  Invoice Date
                </th>
                <th
                  className="text-left"
                  style={{ fontWeight: "800", color: "#7373dd" }}
                >
                  Invoice Time
                </th>
                <th
                className="text-left"
                style={{ fontWeight: "800", color: "#7373dd" }}
              >
                Discount
              </th>
              <th
              className="text-left"
              style={{ fontWeight: "800", color: "#7373dd" }}
            >
            Net Amount
            </th>
            <th
            className="text-left"
            style={{ fontWeight: "800", color: "#7373dd" }}
          >
           Payment Mode
          </th>
                <th
                  className="text-left"
                  style={{ fontWeight: "800", color: "#7373dd" }}
                >
                  Total Amount
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.map((Product1, index) => (
                <tr
                  key={Math.random()}
                  onClick={() => handlemodal(Product1)}
                  data-toggle="modal"
                  data-target="#myModal"
                >
                  <td className="text-left">#{index + 1}</td>
                  <td className="text-left">{Product1?.invNo}</td>
                  <td className="text-left">{Product1?.locName}</td>
                  <td className="text-left">
                    {moment(Product1?.invDate).format("YYYY-MM-DD")}
                  </td>
                  <td className="text-left">{Product1?.invTime}</td>
                  <td className="text-right" style={{ textAlign: "right" }}>{Product1?.discount}</td>
                  <td className="text-right" style={{ textAlign: "right" }}>{Product1?.netAmt}</td>
                  <td className="text-left" style={{ textAlign: "left" }}>{Product1?.paymentMode}</td>
                  
                  <td className="text-right" style={{ textAlign: "right" }}>
                    {Product1.totalAmount}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        <div className="d-block d-sm-flex mt-4 ">
          <span className=""></span>
          <span className="ms-sm-auto "></span>

          <Pagination
            count={ceil}
            color="primary"
            variant="outlined"
            className="pagination float-end mb-4"
            onChange={(e, value) => changePage(value)}
          />
        </div>
      </div>
    </>
  );
};
